import NButton from '../buttons/n-button.js'
import NIconButton from '../buttons/n-icon-button.js'
import NPopupImage from '../n-popup-image.js'

class EVENTS {
}

EVENTS.REMOVE_FILES = 'file-removed'
EVENTS.ADD_FILES = 'file-added'


export default {
    emits: Object.values(EVENTS),
    components: {
        'n-button': NButton,
        'n-icon-button': NIconButton,
        'n-popup-image': NPopupImage,
    },
    props: {
        initialFiles: {
            type: Array,
            default: () => [],
        },
        hideButton: {
            type: Boolean,
            default: false,
        },
        hideRemove: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            files: [],
        }
    },
    methods: {
        handleFileUpload(event) {
            const uploadedFiles = Array.from(event.target.files).map(file => ({
                url: URL.createObjectURL(file),
                file,
            }))

            this.files.push(...uploadedFiles)
            this.$emit('file-added', uploadedFiles.map(f => f.file))
        },
        removeFile(index) {
            const removedFile = this.files.splice(index, 1)[0]
            this.$emit('file-removed', removedFile.file)
        },
        handleInputClick() {
            this.$refs.inputRef.click()
        },
    },
    mounted() {
        this.files = this.initialFiles.map(file => ({
            url: file.access_url,
            file,
        }))
    },
    template: `
    <n-button v-if="!hideButton"
               style="width:calc(50% - 1rem)" outlined small @click="handleInputClick" text="Upload Photo" icon-right="fa-sharp fa-solid fa-camera">
        <input hidden ref="inputRef" type="file" accept="image/*" @change="handleFileUpload" multiple>
    </n-button>
    <div v-if="files?.length > 0" class="image-preview-container" >
        <div v-for="(file, index) in files" :key="index" class="image-preview">
        <n-popup-image :image-src="file.url"></n-popup-image>
        <n-icon-button v-if="!hideRemove" @click="removeFile(index)" style="position: absolute; top:0; right:0; color:#F9524C !important; min-width: 26px;max-width: 26px;min-height: 26px;max-height: 26px; " small outlined color="transparent" icon-class="fa-sharp fa-solid fa-trash"></n-icon-button>
        </div>
    </div>
    `,
}