import NFlex from "../styling/n-flex.js";
import NButton from "../buttons/n-button.js";
import NModalStepper from "./n-modal-stepper.js";

class MODAL_SIZE {
}

MODAL_SIZE.SMALL = 'modal-sm'       // 300px width
MODAL_SIZE.LARGE = 'modal-lg'       // 800px width
MODAL_SIZE.EXTRA_LARGE = 'modal-xl' // 1140px width

const DEFAULT_MODAL_STEPS = {
    STEP_1: 1,
    STEP_2: 2,
    STEP_3: 3,
}

class EVENTS {
}

EVENTS.NEXT_STEP = 'nextStep'

export default {
    inject: ['modals'],
    emits: Object.values(EVENTS),
    watch: {
        modals: {
            handler(updatedModals) {
                if (!updatedModals[this.id]) {
                    return
                }

                if (updatedModals[this.id]?.state === MODAL_STATE.CLOSED) {
                    this.close()
                    return
                }

                const html = updatedModals[this.id]?.template
                if (!html)
                    return

                this.openAndRenderHtml(html)
            },
            deep: true,
        },
    },
    components: {
        "n-flex": NFlex,
        "n-button": NButton,
        "n-modal-stepper": NModalStepper
    },
    props: {
        id: String,
        title: String,
        footer: String,
        size: String, // see MODAL_SIZE class
        modalStepCount: Number,
        dialog: Boolean,
    }
    ,
    data() {
        return {
            html: null,
            content: null,
            isOpen: false,
            modalClass: 'n-modal fade',
            modal: null,
            currentStep: 1,
            MODAL_STEPS: DEFAULT_MODAL_STEPS
        }
    },
    methods: {
        renderContent() {
            let app = this.$root
            app.$data['modalStep'] = this.currentStep
            app.$data['MODAL_STEPS'] = this.MODAL_STEPS
            app.$options.methods['close'] = this.close

            this.content = Vue.shallowRef({
                template: this.html,
                methods: app.$options.methods,
                data: () => app.$data,
                computed: app.$options.computed,
                components: app.$options.components,
                watch: app.$watch,
                provide: app.$options.provide
            })
        },
        getModalClass(size) {
            let modalSize = ''

            if (this.dialog) {
                return 'modal-dialog modal-dialog-centered modal-sm'
            }

            switch (size) {
                case 'small':
                    modalSize = MODAL_SIZE.SMALL
                    break;
                case 'large':
                    modalSize = MODAL_SIZE.LARGE
                    break;
                case 'x-large':
                    modalSize = MODAL_SIZE.EXTRA_LARGE
                    break;
                default:
                // defaults to 500px inherited from modal-dialog
            }
            return "modal-dialog modal-dialog-centered modal-dialog-scrollable " + modalSize
        },
        navigateToModalStep(step) {
            this.$emit(EVENTS.NEXT_STEP, step)
            this.currentStep = step
            this.renderContent(this.html)
        },
        getNorseModalClass(size) {
            if (size === 'fit') {
                this.modalClass += ' fit'
            }
            return this.modalClass
        },
        close() {
            this.isOpen = false
            this.currentStep = 1
            if (this.modal._element) {
                this.modal.hide()
                this.$root.closeModal(this.id)
            }
        },
        openAndRenderHtml(html) {
            this.html = html
            this.renderContent(this.html)

            this.isOpen = true
            this.$nextTick(() => {
                this.modal = new bootstrap.Modal(document.getElementById(this.id))
                this.modal._element.addEventListener('hidden.bs.modal', () => this.$root.closeModal(this.id))
                this.modal.show()
            })
        }
    },
    template:
        `
    <div v-if="isOpen" :id="id" :class="getNorseModalClass(size)" tabindex="-1" aria-hidden="true"> 
        <div :class="getModalClass(size)" >
            <div class="modal-content">
          
                <div v-if="title" class="n-modal-header">
                    <h2 class="n-modal-title">[[[ title ]]]</h2>
                    <div class="n-modal-close" @click="close()">
                        <i class="fa fa-times"></i>
                    </div>
                </div>
                <n-modal-stepper v-if="modalStepCount" 
                                 :current-step="currentStep"
                                 :step-count="modalStepCount"
                                 @navigate-modal="navigateToModalStep($event)">
                                 
                </n-modal-stepper>
                <div class="n-modal-body">
                    <component :is="content"></component>
                </div>
                <n-flex v-if="modalStepCount" padding="1rem 0" gap="1rem" justify-content="center">
                    <template v-if="modalStepCount === currentStep">
                        <slot name="left"></slot>
                    </template>
                    <n-button v-if="modalStepCount > currentStep" 
                              @click="navigateToModalStep(currentStep + 1)" style="width:228px" outlined text="Next"></n-button>
                    <template v-if="modalStepCount === currentStep">
                        <slot name="right"></slot>
                    </template>
                </n-flex>
            </div>
        </div>
    </div>
        `,
}