export default {
    props: {
        isLoading: Boolean,
        redirect: String,
        text: String,
        targetBlank: Boolean,
        color: {
            type: String,
            default: 'primary'
        },
        iconLeft: {
            type: String,
            default: null
        },
        iconRight: {
            type: String,
            default: null
        },
        disabled: Boolean,
        fullWidth: Boolean,
        outlined: Boolean,
        small: Boolean,
        tiny: Boolean,
        type: String
    },
    data() {
        return {
            color_: null,
        };
    },
    methods: {
        initStyle() {
            this.color_ = this.color
        },
        getIconClass() {
            if (this.iconLeft) {
                return ' icon-left'
            }
            if (this.iconRight) {
                return ' icon-right'
            }
            return ''
        },
        getButtonClass() {
            let buttonClass = 'n-button ' + this.getIconClass()
            buttonClass += " " + this.color_

            if (this.fullWidth) {
                buttonClass += ' full-width'
            }

            if (this.outlined) {
                buttonClass += ' outlined'
            }

            if (!this.outlined) {
                buttonClass += ' filled'
            }

            if (this.small) {
                buttonClass += ' small'
            }

            if (this.tiny) {
                buttonClass += ' tiny'
            }

            return buttonClass
        }
    },
    created() {
        this.initStyle()
    },
    template: `
        <button v-bind="$attrs" v-if="!redirect || disabled" :disabled="disabled || isLoading" :class="getButtonClass()" :type="type">
            <slot name="icon-slot-left"></slot>
            <i v-if="iconLeft && !isLoading" :class="iconLeft"/>
            <template v-if="!isLoading">[[[text]]]</template>
            <div class="n-spinner" v-if="isLoading"></div>
            <slot></slot>
            <slot name="icon-slot-right"></slot>
            <i v-if="iconRight && !isLoading" :class="iconRight" />
        </button>
        
        <a v-bind="$attrs" v-if="redirect && !disabled && !isLoading" :href="redirect" :target="targetBlank ? '_blank' : ''" :class="getButtonClass()" :type="type">
            <slot name="icon-slot-left"></slot>
            <i v-if="iconLeft && !isLoading" :class="iconLeft"/>
            <template v-if="!isLoading">[[[text]]]</template>
            <div class="n-spinner" v-if="isLoading"></div>
            <i v-if="iconRight && !isLoading" :class="iconRight"/>
            <slot name="icon-slot-right"></slot>
        </a>
        
        `,
};
