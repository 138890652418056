import NIconButton from "./buttons/n-icon-button.js";

export default {
    components: {
        "n-icon-button": NIconButton,
    },
    props: {
        imageSrc: String,
        altText: String,
    },
    data() {
        return {
            isPopupVisible: false
        };
    },
    methods: {
        showPopup() {
            this.isPopupVisible = true;
        },
        hidePopup() {
            this.isPopupVisible = false;
        }
    },
    mounted() {
    },
    template: `
    <div class="image-wrapper" @click="showPopup">
        <img :src="imageSrc" :alt="altText" class="preview-img">
        <div v-if="isPopupVisible" class="popup" @click.stop="hidePopup">
            <div class="enlarged-image-container">
                <img :src="imageSrc" class="enlarged-image" @click.stop>
                <n-icon-button small icon-class="fa fa-times" outlined style="position: absolute; top: 1rem; right: 1rem"></n-icon-button>
            </div>
        </div>
    </div>
    `,
}
